<template>
    <div class="youtubePlayer">
        <div class="player">
            <div :id="'player-' + index"></div>
            <div class="playPauseButton" @click="togglePlayPause" :class="{ playing: isPlaying }"><span class="material-symbols-outlined">pause_circle</span></div>
        </div>
        <!-- <div class="background-player">
            <div :id="'background-player-' + index"></div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "youtubePlayer",
    props: {
        videoId: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            required: false,
        },
        isPlaying: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            player: null,
            // backgroundPlayer: null,
            ready: false,
        };
    },
    mounted() {
        this.loadYouTubeAPI();
    },
    watch: {
        videoId(newVideoId) {
            if (this.player) {
                this.player.loadVideoById(newVideoId);
            }
        },
        isPlaying(newIsPlaying) {
            this.changePlayerState(newIsPlaying);
        },
    },
    methods: {
        loadYouTubeAPI() {
            if (window.YT && window.YT.Player) {
                this.initPlayer();
            } else {
                window.onYouTubeIframeAPIReady = this.initPlayer;
            }
        },
        initPlayer() {
            this.player = new window.YT.Player("player-" + this.index, {
                height: "100%",
                width: "100%",
                videoId: this.videoId,
                playerVars: {
                    playsinline: 1,
                    controls: 0,
                    modestbranding: 1,
                },
                events: {
                    onReady: this.onPlayerReady,
                    onStateChange: this.onPlayerStateChange,
                },
            });
        },
        changePlayerState(newIsPlaying) {
            if (this.player && this.ready) {
                if (newIsPlaying) {
                    this.player.playVideo();
                    // this.player.unMute();
                } else {
                    this.player.pauseVideo();
                }
            }
        },
        onPlayerReady() {
            this.ready = true;
            // this.player.mute();
            // console.log("Player is ready:", this.index, event);
        },
        onPlayerStateChange() {
            // console.log("Player state changed:", this.index, event.data);
        },
        togglePlayPause() {
            this.$emit('playerStateChange', this.index); // Émet l'événement avec l'index
        },
    },
};
</script>

<style scoped lang="scss">
.youtubePlayer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 30%;
    position: relative;

    .player {
        width: 90%;
        aspect-ratio: 16 / 9;
        z-index: 999;
        transform: translateY(1px);
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        overflow: hidden;
        position: relative;
        .playPauseButton {
            position: absolute;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 1000;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                font-size: 50px;
                color: #A4A6A5;
                display: block;
            }
            &.playing {
                background-color: rgba(0, 0, 0, 0);
                display: none;
            }
        }
    }

    .background-player {
        position: absolute;
        height: 100%;
        aspect-ratio: 16 / 9;
        z-index: 99;
        filter: blur(22px);
    }
}
</style>