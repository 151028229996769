<template>
    <div class="marquee-container" ref="marqueeContainer">
        <span ref="marqueeText" class="marquee-text">{{ text }}</span>
    </div>
</template>

<script>

export default {
    name: "ScrollingText",
    props: {
        text: {
            type: String,
            required: true,
        },
        playAnimation: {
            type: Boolean,
            default: false, // Par défaut, l'animation est désactivée
        },
    },
    data() {
        return {
            textWidth: 0,
            containerWidth: 0,
            timeout: null,
        };
    },
    mounted() {
        this.checkTextOverflow();
    },
    watch: {
        playAnimation(newVal) {
            if (newVal) {
                this.startAnimation(); // Lancer l'animation si `isPlaying` est true
            } else {
                this.stopAnimation(); // Arrêter l'animation si `isPlaying` est false
            }
        },
    },
    methods: {
        checkTextOverflow() {
            const textElement = this.$refs.marqueeText;
            const container = this.$refs.marqueeContainer;

            this.textWidth = textElement.offsetWidth;
            this.containerWidth = container.offsetWidth;

            // if (this.textWidth > this.containerWidth && this.isPlaying) {
            //     this.startAnimation();
            // }
        },
        startAnimation() {
            if (this.textWidth > this.containerWidth) {
                const duration = (this.textWidth + this.containerWidth) / 80; // Calculer la durée de l'animation
                const textElement = this.$refs.marqueeText;

                textElement.style.animationDuration = `${duration}s`;
                this.timeout = setTimeout(() => {
                    textElement.style.animationPlayState = "running"; // Activer l'animation
                }, 3000); // Convertir la durée en millisecondes
            }
        },
        stopAnimation() {
            const textElement = this.$refs.marqueeText;

            // Pause l'animation
            textElement.style.animationPlayState = "paused";

            // Réinitialise la position du texte
            textElement.style.transform = "translateX(0%)";

            // Supprime les styles d'animation pour s'assurer qu'elle ne reprend pas
            textElement.style.animation = "none";

            clearTimeout(this.timeout);
            // Ajoute un léger délai avant de remettre l'animation si nécessaire
            setTimeout(() => {
                textElement.style.animation = ""; // Réinitialise l'animation pour une future utilisation
            }, 50);
        }

    },
};
</script>

<style scoped>
/* Conteneur du texte */
.marquee-container {
    position: relative;
    display: flex;
    overflow: hidden;
    /* Cache le contenu dépassant */
    white-space: nowrap;
    /* Empêche le retour à la ligne */
    align-items: center;
    height: 100%;
    width: 100%;
}

/* Texte coulissant */
.marquee-text {
    position: absolute;
    white-space: nowrap; /* Maintenir le texte en ligne continue */
    animation: marquee linear infinite; /* Animation CSS */
    animation-play-state: paused; /* Animation désactivée par défaut */
}

/* Animation */
@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    90% {
        transform: translateX(calc(-100% + 80vw)); /* Défilement du texte */
    }
    100% {
        transform: translateX(calc(-100% + 80vw)); /* Maintient la position pendant le délai */
    }
}
</style>
