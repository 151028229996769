<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style lang="scss">
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Roboto", serif;
  }
</style>
