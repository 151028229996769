<template>
    <vue-scroll-snap>
        <div class="item" v-for="(track, index) in tracks" :id="'item-' + index" :key="index" :data-index="index">
            <div class="top-spacer"></div>
            <img :src="track.cover" :alt="'Album cover for ' + track.title + ' by ' + track.artist"
                v-on:load="getDominantColor(index)" :id="'image-' + index" />
                <YoutubePlayer :is-playing="track.isPlaying" :videoId="track.youtubeId" :index="index" />
                <!-- @playerStateChange="changePlayerState" -->
            <div class="bottom">
                <div class="data">
                    <div class="text marquee-container">
                        <h1 class="defilement" :id="'title-' + index"><ScrollingText :text="track.title" :playAnimation="track.playAnimation" /></h1>
                        <p class="artists">{{ track.artist }}</p>
                        <p><span class="material-symbols-outlined">album</span><ScrollingText :text="track.album" :playAnimation="track.playAnimation"/></p>
                    </div>
                </div>
                <div class="linksButton">
                    <div class="links">
                        <a class="youtubeBtn"><span class="material-symbols-rounded">favorite</span></a>
                        <a class="youtubeBtn"><span class="material-symbols-rounded">send</span></a>
                        <a :href="'https://www.youtube.com/watch?v=' + track.youtubeId" target="_blank" class="youtubeBtn"><span class="material-symbols-outlined">youtube_activity</span></a>
                        <a :href="track.uri" target="_blank" class="spotifyBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 236.05 225.25">
                                <defs></defs>
                                <path class="cls-1" d="m122.37,3.31C61.99.91,11.1,47.91,8.71,108.29c-2.4,60.38,44.61,111.26,104.98,113.66,60.38,2.4,111.26-44.6,113.66-104.98C229.74,56.59,182.74,5.7,122.37,3.31Zm46.18,160.28c-1.36,2.4-4.01,3.6-6.59,3.24-.79-.11-1.58-.37-2.32-.79-14.46-8.23-30.22-13.59-46.84-15.93-16.62-2.34-33.25-1.53-49.42,2.4-3.51.85-7.04-1.3-7.89-4.81-.85-3.51,1.3-7.04,4.81-7.89,17.78-4.32,36.06-5.21,54.32-2.64,18.26,2.57,35.58,8.46,51.49,17.51,3.13,1.79,4.23,5.77,2.45,8.91Zm14.38-28.72c-2.23,4.12-7.39,5.66-11.51,3.43-16.92-9.15-35.24-15.16-54.45-17.86-19.21-2.7-38.47-1.97-57.26,2.16-1.02.22-2.03.26-3.01.12-3.41-.48-6.33-3.02-7.11-6.59-1.01-4.58,1.89-9.11,6.47-10.12,20.77-4.57,42.06-5.38,63.28-2.4,21.21,2.98,41.46,9.62,60.16,19.74,4.13,2.23,5.66,7.38,3.43,11.51Zm15.94-32.38c-2.1,4.04-6.47,6.13-10.73,5.53-1.15-.16-2.28-.52-3.37-1.08-19.7-10.25-40.92-17.02-63.07-20.13-22.15-3.11-44.42-2.45-66.18,1.97-5.66,1.15-11.17-2.51-12.32-8.16-1.15-5.66,2.51-11.17,8.16-12.32,24.1-4.89,48.74-5.62,73.25-2.18,24.51,3.44,47.99,10.94,69.81,22.29,5.12,2.66,7.11,8.97,4.45,14.09Z" />
                            </svg>
                        </a>
                    </div>
                    <div :class="track.isPlaying ? 'paused' : 'playing'" class="playPauseButtons">
                        <button class="play" v-on:click="changePlayerState(index)"><span class="material-symbols-rounded">play_arrow</span></button>
                        <button class="pause" v-on:click="changePlayerState(index)"><span class="material-symbols-rounded">pause</span></button>
                    </div>
                </div>
            </div>
        </div>
        <Observer v-if="index == tracks.length - 2" @intersect="intersected" />
    </vue-scroll-snap>
</template>

<script>
import VueScrollSnap from "vue-scroll-snap";
import Observer from "../components/Observer.vue";
import DooverAPI from "../common/api";
import YoutubePlayer from "./YoutubePlayer.vue";
import ScrollingText from './ScrollingText.vue';

export default {
    name: "Discover-view",
    components: {
        VueScrollSnap,
        Observer,
        YoutubePlayer,
        ScrollingText,
    },
    data() {
        return {
            search: "",
            tracks: [],
            songObserver: null,
            isPlaying: false,
        };
    },
    mounted() {
        this.getTrack();
    },
    methods: {
        async getTrack() {
            const dooverAPI = new DooverAPI();
            // this.tracks = await dooverAPI.getTopTracks();
            this.tracks = await dooverAPI.getPlaylistTracks();
            

            if (this.tracks.length > 0) {
                setTimeout(() => {
                    this.observeItems(); // Démarrer l'observation après avoir chargé les pistes
                }, 100);
            }
        },
        getDominantColor(value) {
            const dooverAPI = new DooverAPI();
            let image = document.getElementById('image-' + value);
            const color = dooverAPI.get_average_rgb(image);
            if (color.length > 0) {
                this.tracks[value].color = color;
                let lightness = dooverAPI.getSuitableColor(color).rgb;
                document.getElementById('item-' + value).style.backgroundColor = lightness;
                document.getElementById('title-' + value).style.color = lightness;
            }
        },

        observeItems() {
            const options = {
                root: null, // Observer par rapport au viewport
                threshold: 0.75, // Activer quand 75% de l'élément est visible
            };

            // Déconnecter l'ancien observer s'il existe
            if (this.songObserver) {
                this.songObserver.disconnect();
            }

            // Créer un nouvel IntersectionObserver
            this.songObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const index = entry.target.getAttribute('data-index');
                    
                    if (entry.isIntersecting) {
                        this.tracks[index].isPlaying = true;
                        this.tracks[index].playAnimation = true;

                    } else {
                        this.tracks[index].isPlaying = false;
                        this.tracks[index].playAnimation = false;
                    }
                });
            }, options);

            // Observer chaque élément de piste
            this.tracks.forEach((_, index) => {
                const item = document.getElementById(`item-${index}`);

                // Vérifie si l'élément à observer existe
                if (item) {
                    item.setAttribute('data-index', index); // Ajoute un attribut d'index unique
                    this.songObserver.observe(item); // Observer l'élément
                }
            });
        },
        async intersected() {
            console.log('intersected');
            // if (this.tracks.length > 0) {
            //     await this.getMoreTracks();
            // }
        },
        changePlayerState(index) {
            this.tracks[index].isPlaying = !this.tracks[index].isPlaying;
        },
    }
};
</script>

<style lang="scss" scoped>
    // .discover {
    //     background-color: black;
    // }
    .cls-1 {
        fill: #0bb06c;
        stroke-width: 0px;
    }
    .scroll-snap-container {
        color: white;
        height: 100vh;
        position: relative;
        .item {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            .top-spacer {
                display: block;
                height: 7vh;
            }
            img {
                width: 100%;
                display: none;
            }
            .bottom {
                height: 70%;
                background-color: black;
                width: 100%;
                position: relative;
                .data {
                    font-family: "Audiowide", serif;
                    .text {
                        .defilement {
                            // overflow: hidden;
                            white-space: nowrap;
                            animation: defilement 5s linear infinite;
                        }
                        h1 {
                            font-size: 50px;
                            font-weight: 700;
                            margin: 25px 25px;
                            height: 60px;
                        }
                        p {
                            color: #A4A6A5;
                            font-size: 20px;
                            margin: 0 25px 15px;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            span {
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .linksButton {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 40px;
                    
                    .links {
                        width: 50%;
                        display: flex;
                        flex-wrap: wrap;
                        a {
                            background: none;
                            text-decoration: none;
                            border: none;
                            outline: none;
                            color: white;
                            background-color: #3A3A3A;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 10px 0px 10px 25px;
                            .material-symbols-outlined {
                                font-size: 30px;
                            }
                            svg {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                    .playPauseButtons {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.playing {
                            .play {display: block;}
                            .pause {display: none;}
                        }
                        &.paused {
                            .play {display: none;}
                            .pause {display: block;}
                        }
                        button {
                            background: none;
                            border: none;
                            outline: none;
                            color: black;
                            background-color: #A4A6A5;
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .material-symbols-rounded {
                                font-size: 50px;
                                font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24
                            }
                            
                        }
                    }
                }

            }

            // .bottom {
            //     height: 50%;
            //     width: 100%;
            //     display: flex;
            //     flex-direction: column;
            //     color: white;
            //     position: absolute;
            //     bottom: 0;
            //     background-color: black;
            //     .linksButton {
            //         width: 100%;
            //         display: flex;
            //         justify-content: space-evenly;
            //         a {
            //             background: none;
            //             border: none;
            //             outline: none;
            //             color: white;
            //             background-color: #1f1f1f54;
            //             width: 45%;
            //             border-top-left-radius: 15px;
            //             border-top-right-radius: 15px;
            //             display: flex;
            //             justify-content: center;
            //             align-items: center;
            //             .material-symbols-outlined {
            //                 font-size: 30px;
            //                 color: red;
            //             }
            //             svg {
            //                 width: 30px;
            //                 height: 30px;
            //             }
            //         }
            //     }
            //     .data {
            //         width: 100%;
            //         position: relative;
            //         height: 15vh;
            //         display: flex;
            //         align-items: flex-end;
    
            //         .text {
            //             background-color: #1f1f1f54;
            //             padding: 0 10px;
            //             width: 100%;
            //             height: 100%;
    
            //             h1 {
            //                 font-size: 25px;
            //                 font-weight: 700;
            //                 text-transform: uppercase;
            //                 margin: 10px 0;
            //             }
    
            //             p {
            //                 text-transform: uppercase;
            //             }
    
            //         }
            //     }
            // }
        }
    }
</style>
