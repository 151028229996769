<template>
    <div class="discover">
        <searchCard @son="setSon" :newsearch="search" @onToggleMenu="toggleMenu"/>
        <discoverCard></discoverCard>
    </div>
</template>

<script>
import searchCard from "@/components/SearchCard.vue";
import discoverCard from "@/components/DiscoverCard.vue";
// import DooverAPI from "../common/api";

export default {
    name: "Discover-view",
    components: {
        searchCard,
        discoverCard,
    },
    data() {
        return {
            search: '',
            menu: false,
            toggleMenu: false,
        };
    },
    mounted() {

    },
    methods: {
        async setSon(son) {
            console.log(son);
        },
    },
    watch: { 
        onToggleMenu: function() {
            console.log('toggleMenu');
            this.menu = !this.menu;
        },
    }
};
</script>

<style lang="scss" scoped>
.discover {
    background-color: black;
}
</style>
