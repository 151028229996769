import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CallbackView from '../views/CallbackView.vue'
import DiscoverView from '../views/DiscoverView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallbackView,
  },
  {
    path: '/discover/:id',
    name: 'discoverId',
    component: DiscoverView,
    props: true
  },
  {
    path: '/discover',
    name: 'discover',
    component: DiscoverView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
