<template>
  <div class="relative-search">
    <div class="search-bar">
      <input type="text" ref="search" autocomplete="off" v-on:click="resetPlaceholder" name="search" id="search"
        :placeholder="this.placeholder ? this.placeholder : 'Search'" v-debounce:300ms="updateSearch" v-model="search"
        class="search-input">
      <!-- <button :class="resultats.length> 0 ? 'search-btn open' : 'search-btn'"><span class="material-symbols-outlined">search</span></button> -->
      <button v-on:click="toggleMenu()"><span class="material-symbols-rounded">menu</span></button>
    </div>
    <div class="search-result" v-click-outside="removeSearch">
      <!-- <p v-if="resultats.length > 0" class="type-search">{{searchType}}</p> -->
      <div v-for="resultat in resultats" :key="resultat.id" class="resultat">
        <img v-bind:src="resultat.cover" alt="img" v-on:click="returnId(resultat)" />
        <div class="chanson" v-on:click="returnId(resultat)">
          <p class="titre">{{ resultat.title }}</p>
          <p class="artiste">{{ resultat.artist }}</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="'menu' + (menuState ? ' active' : '')">
    <ul>
      <li>where to save?</li>
      <li>Who we are?</li>
      <li>
        <button class="loginSpotify" @click="loginWithSpotify">Log in with Spotify</button>
      </li>
      <li><p v-on:click="logout"><span class="material-symbols-outlined">logout</span> Log out</p></li>
    </ul>
  </div>
</template>

<script>
import { vue3Debounce } from "vue-debounce";
import DooverAPI from "../common/api";
import vClickOutside from 'click-outside-vue3'

export default {
  name: "searchCard",
  props: ["newsearch"],
  emits: ["son", "success"],
  directives: {
    debounce: vue3Debounce({ lock: true }),
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      resultats: [],
      search: "",
      btnAddToPlaylist: false,
      idSearch: "",
      placeholder: "",
      filtres: [],
      searchType: "",
      menuState: false,
    };
  },
  methods: {
    res() {
      this.search = null;
      this.filtres = ""
      this.$refs.search.focus();
      this.resultats = [];
    },
    async updateSearch() {
      const dooverAPI = new DooverAPI();
      this.resultats = [];
      if (this.search != "") {
        this.resultats = await dooverAPI.search(this.search, 7, 0);
      } else {
        this.resultats = [];
        this.btnAddToPlaylist = false;
      }
      this.searchType = "Results";
    },
    async returnId(resultat) {
      // this.placeholder = resultat.name;
      // this.filtres = [resultat];
      // this.$emit("son", [resultat]);
      // this.idSearch = resultat.id;
      // this.resultats = [];
      // this.search= "";
      // this.btnAddToPlaylist = true;
      // window.history.pushState({}, document.title, "/");
      // console.log(await recommendationsAppleMusic(resultat.id));
      console.log(resultat);
    },
    addFiltres(resultat) {
      if (this.filtres.length < 5) {
        this.filtres.push(resultat);
        this.$emit("son", this.filtres);
        this.resultats = [];
        this.search = "";
      }
    },
    truncate(value, length) {
      if (value.length > length) {
        return value.substring(0, length) + "...";
      } else {
        return value;
      }
    },
    removeSearch() {
      if (this.resultats != []) {
        this.resultats = [];
      }
    },
    resetPlaceholder() {
      this.placeholder = "";
      if (this.resultats.length <= 0) {
        this.getUserRecentlyPlayed();
      }
    },
    async getUserRecentlyPlayed() {
      // const dooverAPI = new DooverAPI();
      // this.resultats = await dooverAPI.getUserRecentPlayed()
      this.searchType = "Recently Played";
    },
    toggleMenu() {
      this.menuState = !this.menuState;
    },
    loginWithSpotify() {
      const dooverAPI = new DooverAPI();
      dooverAPI.setCookie('token', 'waiting', 10000);
      window.location.href = dooverAPI.getSpotifyAuthURL();
    },
    logout() {
      const dooverAPI = new DooverAPI();
      dooverAPI.logout();
    },
  },
  watch: {
    newsearch: function (newsearch) {
      this.placeholder = newsearch.name;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.relative-search {
  height: 7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0px;
  z-index: 9999;
  width: 100%;
  background-color: #1f1f1f54;

  .search-bar {
    display: flex;
    justify-content: space-evenly;
    padding: 5px 0;

    button {
      background: none;
      border: none;
      outline: none;
      color: #f25b22;
    }

    .search-input {
      width: 80%;
      height: 40px;
      border-radius: 25px;
      border: solid 2px #f25b22;
      outline: none;
      padding: 5px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      background: none;
      color: white;

      &::placeholder {
        color: white;
      }
    }
  }

  .search-result {
    position: absolute;
    background-color: white;
    top: 7vh;
    z-index: 9999;
    width: 100%;

    .resultat {
      height: 75px;
      display: flex;
      width: 100%;
      border-bottom: solid 1px #f25b22;

      &:first-of-type {
        border-top: solid 1px #f25b22;
      }

      img {
        height: 100%;
        aspect-ratio: 1/1;
      }

      .chanson {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 5px;
        width: 100%;

        p {
          width: 100%;
        }

        .titre {
          font-weight: 500;
          font-size: 1.1rem;
          text-transform: uppercase;
        }
      }
    }
  }
}

.menu {
  position: fixed;
  top: 7vh;
  background-color: #161419;
  color: white;
  height: calc(100vh - 7vh);
  z-index: 9999;
  width: 100%;
  text-transform: uppercase;
  transform: translateX(-101%);
  transition: 0.3s ease-in-out;

  &.active {
    transform: translateX(0);
  }

  ul {
    list-style-type: none;

    li {
      padding: 10px;
    }
  }
  .loginSpotify {
    background-color: #1db954;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    border-radius: 25px;
  }
}
</style>
